<template>
  <div class="question-block">
    <h3>Question {{ questionIndex + 1 }}: {{ question.title }}</h3>
    <p>{{ question.detail }}</p>
    <sl-button
        v-if="question.solutions.length > 0"
        variant="warning"
        @click="showSolution(questionIndex)"
    >
      Show Solution
    </sl-button>
    <sl-button
        v-else
        variant="default"
        disabled
    >
      No solution to show now &nbsp;&nbsp;: (
    </sl-button>
  </div>
</template>

<script setup>
import '@shoelace-style/shoelace/dist/components/button/button.js';

const props = defineProps({
  questionIndex: {
    type: Number,
    required: true
  },
  question: {
    type: Object,
    required: true
  }
});

const emits = defineEmits(['show-solution']);

const showSolution = (idx) => {
  emits('show-solution', idx);
}
</script>
<style>
.question-block {
  width: 100%;
  background: var(--sl-color-cyan-950);
  padding: 1px 8px;
  margin: 0 auto;
  min-height: 22rem;
  border-bottom: 2px solid #09242e;

  & p,
  & h3 {
    color: var(--sl-color-cyan-50);
  }
}
</style>

