<template>
  <div class="interview-page main-layout">

    <div v-if="isMobile" class="interview-page__mobile-questions">
      <LeftDrawer ref="leftdrawer">
        <InterviewQuestionBlock
            v-for="(question, idx) in codingQuestions"
            :key="question.id"
            :question-index="idx"
            :question="question"
            @show-solution="showSolution"
        />
      </LeftDrawer>
    </div>

    <div v-else class="interview-page__questions">
      <InterviewQuestionBlock
          v-for="(question, idx) in codingQuestions"
          :key="question.id"
          :question-index="idx"
          :question="question"
          @show-solution="showSolution"
      />
    </div>

    <div class="interview-page__coding-section" :class="{ 'mobile-view' : isMobile }">

      <div class="interview-page__code-editor">
        <Editor
            :question-solution-code="questionSolution"
            @run-code="executeCode"
            @clear-question-solution="clearCodingQuestionSolution"
        />
      </div>

      <div class="interview-page__results">
        <sl-button
            class="interview-page__clear-btn"
            variant="neutral"
            @click="clearFrame"
        >
          Clear
        </sl-button>

        <iframe
            :srcdoc="iframeContent"
            id="code_execution_frame"
            sandbox="allow-scripts"
        >
        </iframe>
      </div>
    </div>
  </div>
</template>

<script setup>
import InterviewQuestionBlock from '@/components/InterviewQuestionBlock/InterviewQuestionBlock.vue';
import Editor from '@/components/Editor/Editor.vue';
import { computed, ref, useTemplateRef } from 'vue';
import LeftDrawer from '@/components/LeftDrawer/LeftDrawer.vue';
import { useStore } from 'vuex';

const store = useStore();

const isMobile = computed(() => {
  return store.state.isMobile;
});

const leftDrawer = useTemplateRef('leftdrawer');

// holds each log/output line from code execution
const codeOutputResults = ref([]);

// holds iframe heading + all code execution results/output lines + styles
const iframeContent = ref('');

const codingQuestions = computed(() => {
  return store.state.codingQuestions;
});

// holds code for currently selected coding question / null if no questions are currently selected
const questionSolution = ref(null);

const showSolution = (index) => {
  questionSolution.value = codingQuestions.value[index].solutions?.map(solution => solution.code)
      .join('\n\n\n');

  // Drawer only exists on mobile view
  if (isMobile.value) {
    leftDrawer.value.hideDrawer();
  }
}

const clearCodingQuestionSolution = () => {
  questionSolution.value = null;
}

/*
  Having issues importing the web-worker.js file so I added the content as a blob.
  When added this way, strings must be wrapped in single or double quotes.
 */
const worker = new Worker(URL.createObjectURL(new Blob([`
      self.onmessage = (event) => {
        const code = event.data;

        console.log = function(message) {
          self.postMessage({ type: 'log', data: message });
        };

        console.warn = function(message) {
          self.postMessage({ type: 'warn', data: message });
        };

        console.error = function(message) {
          self.postMessage({ type: 'error', data: message });
        };

        console.info = function(message) {
          self.postMessage({ type: 'info', data: message });
        };

        let start, end;

        try {
          start = performance.now();
          const evalResult = eval(code);

          if (evalResult === false) {
            self.postMessage({ type: 'warn', data: evalResult });
          } else {
            self.postMessage({ type: 'log', data: evalResult });
          }

          end = performance.now();
        } catch (error) {

          self.postMessage({ type: 'error', data: error.message });
        } finally {
          const duration = parseFloat(end - start).toFixed(2);
          console.info('Execution completed in  ' + duration +' milliseconds.');
        }
      };
    `], { type: 'application/javascript' })));

const executeCode = (code) => {
  codeOutputResults.value = [];
  worker.postMessage(code);
}

worker.onmessage = (event) => {
  let baseIframeContent = `<h3 class="code-output--heading white-text">Code Output:</h3>`;

  /*
    We exit early if the type is not set but we are allowing null, undefined and false here since they can be valid return values expected by a user.

    For example, executing the following should return undefined twice.

      let abc;
      console.log(abc);
      typeof abc;
   */
  if (!event.data?.type) {
    baseIframeContent += `<p class="error-text">Error executing code.</p>`;
    iframeContent.value = baseIframeContent;
    return;
  }

  codeOutputResults.value.push(event.data);

  let outputForIframe = '';
  codeOutputResults.value.forEach(outputResult => {
    outputForIframe += `<p class="${outputResult.type}-text">${outputResult.data}</p>`;
  });

  baseIframeContent += outputForIframe;

  const iframeContentWithStyles = baseIframeContent += `
  <style>
      .white-text {
      color: white;
    }
    .log-text {
      color: lightgray;
    }
    .info-text {
      color: lightblue;
    }
    .warn-text {
      color: orange;
    }
    .error-text {
      color: red;
    }
  </style>`;

  iframeContent.value = baseIframeContent;
}

const clearFrame = () => {
  codeOutputResults.value = [];
  iframeContent.value = '';
}
</script>

<style lang="scss">
.interview-page {
  display: flex;
  background: #0d2731;
  height: calc(100% - 50px); // 50px is the height of the header
  width: 100%;

  &__questions {
    width: 40%;
    background: var(--sl-color-cyan-800);
    overflow-y: auto;
    scrollbar-color: var(--sl-color-cyan-900) var(--sl-color-cyan-700);
    scrollbar-width: thin;
  }

  &__coding-section {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    width: auto;
    background: black;

    &.mobile-view {
      margin-top: var(--sl-spacing-3x-large);
    }
  }

  &__code-editor {
    height: 60%;
  }

  &__results {
    position: relative;
    height: 100%;
    background: black;
  }

  &__clear-btn {
    position: absolute;
    top: var(--sl-spacing-2x-small);
    right: var(--sl-spacing-2x-small);
    z-index: 2;
  }
}

// code execution frame
#code_execution_frame {
  padding-top: var(--sl-spacing-1x-large);
  width: 100%;
  height: 100%;
  border: 0;
  color: white;

  & pre {
    color: white;
  }
}
</style>
