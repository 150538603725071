import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import('./global-styles/global-styles.scss');

import '@shoelace-style/shoelace/dist/themes/light.css';
import { setBasePath } from '@shoelace-style/shoelace/dist/utilities/base-path';

setBasePath('https://cdn.jsdelivr.net/npm/@shoelace-style/shoelace@2.17.1/cdn/');


createApp(App).use(store).use(router).mount('#app')
