import { createStore } from 'vuex'

export default createStore({
  state: {
    isMobile: false,
    codingQuestions: [
      {
        "id": 1,
        "title": "Simple code question",
        "detail": "Given an array of nums, e.g [3,4,8,9] and a target e.g 12, return the indexes of the numbers that total the sum when added up. \ne.g 1,2",
        "solutions": [
          {
            "code" : "function getSumIndexes(listOfNums, targetToMatch) {\n" +
              "    let currentValue;\n" +
              "    let sum;\n" +
              "    let indexes = [];\n" +
              "    \n" +
              "    for (let i = 0; i < listOfNums.length; i++) {\n" +
              "        currentValue = listOfNums[i];\n" +
              "        for (let j = i + 1; j < listOfNums.length; j++) {\n" +
              "            sum = currentValue + listOfNums[j];\n" +
              "            if (sum === targetToMatch) {\n" +
              "                indexes.push(i, j);\n" +
              "                break;\n" +
              "            }\n" +
              "        }\n" +
              "\n" +
              "       if (sum === targetToMatch) {\n" +
              "          break;\n" +
              "        }\n" +
              "    }\n" +
              "\n" +
              "    return indexes;\n" +
              "}"
          },
          {
            "code": "// expect output of 1,2\n" +
              "getSumIndexes([1,3,4], 7);"
          }
        ]
      },
      {
        "id": 2,
        "title": "Working with promises.",
        "detail": "Given we have 4 urls that return a number when called, write a function that will call all 4 urls asynchronously, add the numbers and return the result.\n If any of the requests fail, return `null`",
        "solutions": []
      }
    ]
  },
  getters: {
  },
  mutations: {
    setIsMobile: (state, value)  => state.isMobile = value
  },
  actions: {
  },
  modules: {
  }
})
