<template>
  <div v-if="noViewOnMobile" class="no-view-mobile-overlay">
    <h2>
      Please turn your phone to landscape mode to see this application.
    </h2>
  </div>
  <header>
    <h1 class="app-title">Interview Questions</h1>
  </header>
  <router-view />
  <!-- <footer> here -->
</template>

<script setup>
import { useStore } from 'vuex';
import { onBeforeUnmount, onMounted, ref } from 'vue';

const store = useStore();

const noViewOnMobile = ref(false);

const onResize = () => {
  const isMobile = window.innerWidth <= 850;
  store.commit('setIsMobile', isMobile);
  noViewOnMobile.value = isMobile && window.matchMedia("(orientation: portrait)").matches;
}

onMounted(() => {
  onResize()
  window.addEventListener('resize', onResize, { passive: true })
});

onBeforeUnmount(() => {
  if (typeof window !== 'undefined') {
    window.removeEventListener('resize', onResize, { passive: true })
  }
});
</script>

<style>
#app {
  height: 100%;
  overflow-y: hidden;
}
h1.app-title {
  margin-top: 0;
  color: #d6dddd;
}
header {
  height: 50px;
  text-align: center;
  padding: var(--sl-spacing-x-small) 0;
  background: #071c24;
}

.no-view-mobile-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  z-index: 3;
  background: #071c24;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding: var(--sl-spacing-small);
  text-align: center;
}
</style>
