<template>
  <sl-drawer ref="drawer" label="Drawer" placement="start" class="drawer-placement-start">
    <slot/>
    <h2 slot="label">Questions List</h2>
    <sl-button slot="footer" variant="primary" @click="hideDrawer">Close</sl-button>
  </sl-drawer>

  <sl-button
      class="open-drawer"
      variant="primary"
      @click="showDrawer">
    View Questions
  </sl-button>
</template>

<script setup>
import '@shoelace-style/shoelace/dist/components/drawer/drawer.js';
import { useTemplateRef } from 'vue';

const drawer = useTemplateRef('drawer');

const showDrawer = () => {
  drawer.value.show();
}

const hideDrawer = () => {
  drawer.value.hide();
}

defineExpose({
  hideDrawer
});
</script>

<style>
sl-button.open-drawer {
  position: absolute;
  left: var(--sl-spacing-2x-small);
  top: 3.30rem;
  z-index: 1;
}

sl-drawer[open]::part(header),
sl-drawer[open]::part(footer) {
  background: #071c24;
}

sl-drawer[open]::part(body) {
  background: #0c2a35;
}


</style>
