<template>
  <div class="code-editor-page">
    <sl-button
        class="code-editor-page__run-btn"
        variant="success"
        :disabled="!code"
        @click="runCode"
    >
      Run&nbsp;
    </sl-button>
    <sl-button
        class="code-editor-page__clear-btn"
        variant="neutral"
        @click="clearCode"
    >
      Clear
    </sl-button>
    <codemirror
        class="code-editor-page__code-mirror"
        v-model="code"
        placeholder="Add your code here..."
        :style="{
          'min-height': '600px',
          'overflow-y': 'auto'
        }"
        :autofocus="true"
        :indent-with-tab="true"
        :tab-size="2"
        :extensions="extensions"
        @ready="handleReady"
    />
  </div>
</template>

<script setup>
import { ref, shallowRef, watch } from 'vue'
import { Codemirror } from 'vue-codemirror'
import { javascript } from '@codemirror/lang-javascript'
import { oneDark } from '@codemirror/theme-one-dark'
import '@shoelace-style/shoelace/dist/components/button/button.js';
import DOMPurify from 'dompurify';

// Codemirror EditorView instance ref
const view = shallowRef();

const emits = defineEmits(['run-code', 'clear-question-solution']);

const props = defineProps({
  questionSolutionCode: {
    type: String,
    required: false
  }
});

const extensions = [javascript(), oneDark]

// Starting code if no code has been passed in yet
const code = ref(`// Enter your code solution here
console.log('Hello, world!')
`);

watch(() => props.questionSolutionCode, (newValue, oldValue) => {
  code.value = newValue;
});

const handleReady = (payload) => {
  view.value = payload.view
}

const runCode = () => {
  emits('run-code', code.value)
}

const clearCode = () => {
  code.value = '';
  emits('clear-question-solution');
}
</script>

<style lang="scss">
.code-editor-page {
  position: relative;

  &__code-mirror{
    height: 100%;
  }

  &__run-btn {
    position: absolute;
    top: var(--sl-spacing-2x-small);
    right: var(--sl-spacing-2x-small);
    z-index: 2;
  }

  &__clear-btn {
    position: absolute;
    top: 4rem;
    right: var(--sl-spacing-2x-small);
    z-index: 2;
  }
}
</style>
